import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';
import * as THREE from 'three';

window.THREE = THREE;

require('gsap/TweenMax');

// THREE libs
// require('lib/three/custom/controls/DeviceOrientationControls.js');
require('three/examples/js/renderers/CSS3DRenderer.js');

// react app
const store = createStore(reducers, applyMiddleware(thunk));

// get data url
const rootEl = document.getElementById("root");

ReactDOM.render(
	<Provider store={store}>
		<App dataUrl={rootEl.getAttribute("data-page-content")}
				 rootEl={rootEl}
		/>
	</Provider>,
	rootEl
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
