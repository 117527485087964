import React from 'react'
import PageTransitionWrapper from './PageTransitionWrapper'
import styles from './IllustrationPage.module.scss'
import ParallaxTeaser from '../components/ParallaxTeaser/ParallaxTeaser'
import { Link } from 'react-router-dom'

const IllustrationPage = (props) => {
	const { content, windowSize, scrollY } = props

	if (!content) return null

	return (
		<div className={ `text-dark ${ styles.container }` }>
			{ content.galleries.map((gallery, index) => (
					<div className={ styles.snapChild } key={`gallery_${index}`}>
						<ParallaxTeaser scrollY={ scrollY }
										windowSize={ windowSize }
										title={ gallery.title }
										link={ 'show more' }
										slides={ gallery.slides }
						/>
					</div>
				),
			) }

			<div className={ `container-fluid bg-c-4 ${ styles.snapChild }` }>
				<div className="container py-5">
					<div className="row">
						<div className="col col-12 offset-lg-1 col-lg-10 text-right">
							<Link className="button" to={ content.linkContact.path }
								  dangerouslySetInnerHTML={ { __html: content.linkContact.label } }/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default PageTransitionWrapper(IllustrationPage)
