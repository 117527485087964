import React from 'react';
import MovingParticleMaterial from "./material/MovingParticleMaterial";
import MobileDetect from "mobile-detect";
import point from '../../static/img/point@2x.png';
import styles from './WebGLView.module.scss';

class WebGLView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			setupComplete: false
		};

		this.THREE = window.THREE;
		this.geometry = new this.THREE.BufferGeometry();
		this.group = new this.THREE.Group();
		this.sceneContainerRef = React.createRef();

		this.md = new MobileDetect(window.navigator.userAgent);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const {scene} = this.props;

		// scene, camera-min-pos and data exists
		// -------------------------------------
		if (!this.state.setupComplete && scene) {
			this.init();
			this.animate();

			this.setState(
				{
					setupComplete: true
				}
			);
		}
	}

	init() {
		const {scene, particleCountPerSphere, renderer, radius, size, sizeMobile, radiusMultiply} = this.props;

		const positions = [],
			colors = [],
			sizes = [],
			uids = [];
		const color = new this.THREE.Color(0xFFFFFF);
		const colorHighlight1 = new this.THREE.Color(0x90c141);
		const colorHighlight2 = new this.THREE.Color(0x0050d1);
		const particleSize = this.md.phone() ? sizeMobile : size;

		// get particles for data-count
		// const sphereCount = Math.ceil(data.length / itemsPerSphere) + 1;
		const particleCount =  particleCountPerSphere;

		for (let i = 0; i < particleCountPerSphere; i++) {
			const x = ( ( i % 11 ) * radius ) - radius * 5 * radiusMultiply.x;
			const y = ( - ( Math.floor( i / 11 ) % 11 ) * radius) + radius * 5 * radiusMultiply.x;
			const z = -( Math.floor( i / 121 ) ) * radius * 2 * radiusMultiply.z + radius;

			positions.push(x);
			positions.push(y);
			positions.push(z);

			// minZPos = Math.min(minZPos, z);

			if(i % 5 === 0) {
				colors.push(colorHighlight1.r, colorHighlight1.g, colorHighlight1.b);
			}else if(i % 2 === 0) {
				colors.push(colorHighlight2.r, colorHighlight2.g, colorHighlight2.b);
			}else {
				colors.push(color.r, color.g, color.b);
			}

			uids.push(Math.random() * particleCount);

			sizes.push(particleSize);
		}

		this.geometry.addAttribute('position', new this.THREE.Float32BufferAttribute(positions, 3));
		this.geometry.addAttribute('color', new this.THREE.Float32BufferAttribute(colors, 3));
		this.geometry.addAttribute('size', new this.THREE.Float32BufferAttribute(sizes, 1));
		this.geometry.addAttribute('uid', new this.THREE.Float32BufferAttribute(uids, 1));

		let material = new MovingParticleMaterial(
			{
				blending: this.THREE.AdditiveBlending,
				depthTest: true,
				transparent: true,
				vertexColors: true
			},
			point
		);

		// let material = new StaticParticleMaterial(
		// 	{
		// 		blending: this.THREE.AdditiveBlending,
		// 		depthTest: true,
		// 		transparent: true,
		// 		vertexColors: true
		// 	},
		// 	point
		// );

		this.particleSystem = new this.THREE.Points(this.geometry, material);

		this.group.add(this.particleSystem);
		scene.add(this.group);

		this.sceneContainerRef.current.appendChild(renderer.domElement);
	}

	animate() {
		window.requestAnimationFrame(() => {
			this.animate()
		});

		if(this.particleSystem) {
			this.particleSystem.material.uniforms.time.value += 0.01;
		}
	}

	render() {
		return (
			<div
				className={styles.glContainer}
				ref={this.sceneContainerRef}
			/>
		)
	}
}

WebGLView.defaultProps = {
	scene: null,
	particleCountPerSphere: 800, // particles per 15 items (one item sphere)
	radius: 350, // of one sphere
	radiusMultiply: {x: 1, y: 1, z: 1}, // scale of position
	size: window.devicePixelRatio === 1 ? 5 : 10,
	sizeMobile: window.devicePixelRatio === 1 ? 4 : 8,
};

export default WebGLView;
