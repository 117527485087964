import React from 'react';
import PageTransitionWrapper from './PageTransitionWrapper';

class ContactPage extends React.Component {

	render() {
		const {content} = this.props;

		if(!content) return null;

		return (
			<div className="container text-light page-bottom-padding">
				<div className="row mb-4">
					<div className="col col-1 d-none d-lg-block" />
					<div className="col col-12 col-lg-10">
						<h1 className="text-c-7" dangerouslySetInnerHTML={{__html: content.title}} />
					</div>
				</div>

				<div className="row mb-2 mb-md-3">
					<div className="col col-1 d-none d-lg-block" />
					<div className="col col-12 col-lg-10">
						<h3 className="" dangerouslySetInnerHTML={{__html: content.subHeadline}} />
					</div>
				</div>

				{content.chapters.map((data, index) => (
					<React.Fragment key={index}>
						<div className="row">
							<div className="col col-1 d-none d-lg-block" />
							<div className="col col-12 col-lg-10">
								<h5 className="text-c-7" dangerouslySetInnerHTML={{__html: data.title}} />
							</div>
						</div>

						<div className="row mb-4">
							<div className="col col-1 d-none d-lg-block" />
							<div className="col col-12 col-lg-10">
								<p dangerouslySetInnerHTML={{__html: data.copy}} />
							</div>
						</div>
					</React.Fragment>
				))}
			</div>
		);
	}
}

export default PageTransitionWrapper(ContactPage);