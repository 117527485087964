export const map = (value, in_min, in_max, out_min, out_max) => {
	let result = (value - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
	result = Math.min(result, out_max);
	return result < out_min ? out_min : result;
};

export const bounds = (value, min, max) => {
	return Math.min(Math.max(value, min), max);
};

/**
 * get fov for camera to fit object, depending on distance
 * @param aspect aspect ration of camera
 * @param distance distance of camera to the object
 * @param itemWidth width of the object to fit
 * @return {number} fov
 */
export const getFOVToFitObject = (aspect, distance, itemWidth) => {
	return 2 * Math.atan( ( itemWidth / aspect ) / ( 2 * distance) ) * ( 180 / Math.PI ); // in degrees
};

export const getDistanceToFitObject = (aspect, fov, itemWidth) => {
	return ((1 / (Math.tan((fov / ( 180 / Math.PI )) / 2))) * ( itemWidth / aspect )) / 2;
};

export const randomNumber = (min, max) => {
	return Math.floor(Math.random() * (1 + max - min) + min);
};