import * as ActionType from "../constants/ActionType";
import jsonAPI from  "../api/jsonAPI";

export const fetchData = (url) => async dispatch => {
	const result = await jsonAPI.get(url);

	dispatch(
		{
			type: ActionType.FETCH_DATA_COMPLETE,
			payload: result.data
		}
	);
};

export const initSitemap = () => {
	return {
		type: ActionType.INIT_SITEMAP
	}
};

export const setMenuState = (value) => {
	return {
		type: ActionType.SET_MENU_STATE,
		payload: value
	}
};

export const toggleGalleryOverlay = (data = null, index = 0) => {
	return {
		type: ActionType.TOGGLE_GALLERY_OVERLAY,
		payload: {data, index}
	}
};

export const setScrollbarWidth = (width = 0) => {
	return {
		type: ActionType.SET_SCROLLBAR_WIDTH,
		payload: width
	}
};
