import React from 'react'
import Slider from 'react-slick'
import styles from './GalleryOverlay.module.scss'
import * as MediaQuery from '../../constants/MediaQuery'
import * as ResizeUtil from '../../utils/ResizeUtil'
import { connect } from 'react-redux'
import { toggleGalleryOverlay } from '../../actions'
import { ReactComponent as CloseIcon } from '../../static/img/close.svg'
import { ReactComponent as ArrowLeft } from '../../static/img/arrow-left.svg'
import { ReactComponent as ArrowRight } from '../../static/img/arrow-right.svg'
import TimelineMax from 'gsap/TimelineMax'
import * as Ease from 'gsap/EasePack'

function PrevArrow (props) {
	const { className, style, onClick } = props
	return (
		<ArrowLeft className={ className }
				   style={ { ...style } }
				   onClick={ onClick }/>
	)
}

function NextArrow (props) {
	const { className, style, onClick } = props
	return (
		<ArrowRight className={ className }
					style={ { ...style } }
					onClick={ onClick }/>
	)
}

class GalleryOverlay extends React.Component {
	constructor (props) {
		super(props)

		this.tlShow = null

		this.containerRef = React.createRef()
		this.bgRef = React.createRef()

		this.closeOverlayHandler = this.onCloseOverlay.bind(this)
	}

	componentWillUnmount () {
		if (this.tlShow) {
			this.tlShow.kill()
			this.tlShow = null
		}
	}

	componentDidUpdate (prevProps, prevState) {
		const { windowSize, overlayData } = this.props
		const prevWinSize = prevProps.windowSize
		const prevData = prevProps.overlayData

		// win resize
		if (prevWinSize && windowSize) {
			if (prevWinSize.ratio !== windowSize.ratio) {
				this.resizeContainer()
			}
		}

		// show if overlay content is set
		if (!prevData && overlayData) {
			this.resizeContainer()
			this.show()
		}
	}

	show () {
		if (!this.containerRef.current || !this.containerRef.current) return

		this.tlShow = new TimelineMax({ delay: 0.2 })
		this.tlShow.addLabel('start')
		this.tlShow.fromTo(this.bgRef.current, 0.4, { autoAlpha: 0 }, { autoAlpha: 0.75, ease: Ease.Power3.easeOut }, 'start')
		this.tlShow.fromTo(this.containerRef.current, 0.6, { autoAlpha: 0, y: 60 }, {
			autoAlpha: 1,
			y: 0,
			ease: Ease.Power2.easeOut,
		}, 'start+=0.2')
	}

	resizeContainer () {
		if (!this.containerRef.current) return

		const { windowSize, borderSize, maxImageWidth, maxImageHeight } = this.props

		let maxWidth = windowSize.width >= MediaQuery.MD ? windowSize.width - borderSize : windowSize.width
		let maxHeight = windowSize.width >= MediaQuery.MD ? windowSize.height - borderSize : windowSize.height

		// make sure that not larger than max image size
		maxWidth = Math.min(maxImageWidth, maxWidth)
		maxHeight = Math.min(maxImageHeight, maxHeight)

		const targetSize = ResizeUtil.scale(maxImageWidth, maxImageHeight, maxWidth, maxHeight, ResizeUtil.SCALE_PROPORTIONAL_INSIDE)

		this.containerRef.current.removeAttribute('style')
		this.containerRef.current.setAttribute('style', `
			width: ${ targetSize.size.width }px;
			height: ${ targetSize.size.height }px;
		`)
	}

	onCloseOverlay (event) {
		this.props.toggleGalleryOverlay()
	}

	render () {
		const { sliderSettings, overlayData, slideIndex } = this.props

		if (!overlayData) return null

		const settings = {
			prevArrow: <PrevArrow/>,
			nextArrow: <NextArrow/>,
			initialSlide: slideIndex,
			...sliderSettings,
		}

		return (
			<div className={ styles.sliderComponent }>
				<div className={ styles.sliderBg }
					 onClick={ this.closeOverlayHandler }
					 ref={ this.bgRef }
				/>
				<div className={ styles.sliderContainer } ref={ this.containerRef }>
					<Slider { ...settings }>
						{ overlayData.map(({ imageOverlay, alt, description }, index) => {
								return (
									<div className={styles.imageContainer} key={ index }>
										<img className="w-100" src={ imageOverlay } alt={ alt }/>
										{ description && <p className={ styles.description } dangerouslySetInnerHTML={{__html: description}} /> }
									</div>
								)
							},
						) }
					</Slider>
					<span className={ styles.btnClose }
						  onClick={ this.closeOverlayHandler }>
						<CloseIcon className={ styles.closeSVG }/>
					</span>
				</div>
			</div>
		)
	}
}

GalleryOverlay.defaultProps = {
	maxImageWidth: 1175 * 1.5,
	maxImageHeight: 770 * 1.5,
	ratio: 770 / 1175,
	borderSize: 50,
	sliderSettings: {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		accessibility: true,
	},
}

const mapStateToProps = (state) => {
	return {
		overlayData: state.overlayData ? state.overlayData.data : null,
		slideIndex: state.overlayData ? state.overlayData.index : 0,
	}
}

export default connect(mapStateToProps, {
	toggleGalleryOverlay,
})(GalleryOverlay)