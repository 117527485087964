import React from 'react';
import TimelineMax from "gsap/TimelineMax";
import * as Ease from "gsap/EasePack";
import styles from "./PageTransitionWrapper.module.scss";
import classNames from "classnames";
import _each from 'lodash/each';
import * as TransitionType from '../constants/TransitionType';
import {connect} from 'react-redux';
import {setScrollbarWidth} from '../actions';

function PageTransitionWrapper (WrappedComponent) {
	class Page extends React.Component {
		constructor(props) {
			super(props);

			this.state = {
				scrollY: 0
			};

			this.containerRef = React.createRef();
			this.contentRef = React.createRef();

			this.tl = null;
			this.currAnimType = null;

			this.col1Ref = React.createRef();
			this.col2Ref = React.createRef();
			this.col3Ref = React.createRef();
			this.col4Ref = React.createRef();
			this.col5Ref = React.createRef();

			this.scrollHandler = this.onScroll.bind(this);
		}

		onScroll(event) {
			const scrollY = this.contentRef.current.scrollTop;

			if(this.state.scrollY !== scrollY) {
				this.setState({scrollY});
			}
		}

		show() {
			this.killAnimation();
			const colRefs = [
				this.col1Ref.current, this.col2Ref.current, this.col3Ref.current, this.col4Ref.current
			];

			this.tl = new TimelineMax({delay: 0.3});

			// bg, content
			this.tl.addLabel('start');
			_each(colRefs, (col, index)  => {
				const delay = index === 0 || index === colRefs.length - 1 ? 0 : 0.05 * index;
				this.tl.fromTo(col, 0.4, {y: '100vh', height: 0}, {y: 0, height: '100vh', delay: delay, ease: Ease.Sine.easeOut}, 'start');
			});
			this.tl.fromTo(this.col5Ref.current, 0.4, {y: '100vh', height: 0}, {y: 0, height: '100vh', delay: 0.2, ease: Ease.Sine.easeOut}, 'start');
			this.tl.fromTo(this.contentRef.current, 0.4, {y: 0, autoAlpha: 0}, {y: 0, autoAlpha: 1, ease: Ease.Sine.easeInOut}, 'start+=0.2');
			this.tl.addLabel('end');

			this.tl.timeScale(0.75);
		}

		hide() {
			this.killAnimation();
			const colRefs = [
				this.col1Ref.current, this.col2Ref.current, this.col3Ref.current, this.col4Ref.current
			];

			this.tl = new TimelineMax({delay: 0.3});

			this.tl.addLabel('start');
			_each(colRefs, (col, index)  => {
				const delay = index === 0 || index === colRefs.length - 1 ? 0 : 0.05 * index;
				this.tl.fromTo(col, 0.4, {y: 0, height: '100vh'}, {y: 0, height: 0, delay: delay, ease: Ease.Sine.easeInOut}, 'start+=0.2');
			});
			this.tl.fromTo(this.col5Ref.current, 0.4, {y: 0, height: '100vh'}, {y: 0, height: 0, delay: 0, ease: Ease.Sine.easeOut}, 'start+=0.2');
			this.tl.fromTo(this.contentRef.current, 0.4, {y: 0, autoAlpha: 1}, {y: '-40vh', autoAlpha: 0, ease: Ease.Sine.easeOut}, 'start');
			this.tl.addLabel('end');
			this.tl.timeScale(0.75);
		}

		killAnimation() {
			if(this.tl) {
				this.tl.kill();
			}
		}

		componentWillUnmount() {
			this.contentRef.current.removeEventListener('scroll', this.scrollHandler);
		}
		componentDidMount() {
			const {setScrollbarWidth} = this.props;

			this.contentRef.current.addEventListener('scroll', this.scrollHandler);

			// Get the scrollbar width
			const scrollbarWidth = this.contentRef.current.offsetWidth - this.contentRef.current.clientWidth;
			setScrollbarWidth(scrollbarWidth);
		}

		componentDidUpdate(prevProps, prevState) {
			const {transitionState} = this.props;

			if(transitionState !== this.currAnimType) {
				this.currAnimType = transitionState;

				switch(transitionState) {
					case TransitionType.EXITING:
						this.hide();
						break;

					case TransitionType.ENTERING:
						this.show();
						break;

					default:
						break;
				}
			}
		}

		render() {
			const {transitionState, id, scrollbarWidth, snapScroll} = this.props;

			const containerClasses = classNames(
				styles.pageContainer,
				// computed style classes in css module!
				{[styles.pageContainerShow]: transitionState === TransitionType.ENTERING || transitionState === TransitionType.ENTERED},
				{[styles.pageContainerHide]: transitionState === TransitionType.EXITING || transitionState === TransitionType.EXITED}
			);

			const contentClasses = classNames(
				styles.pageContent,
				{[styles.pageContentSnapScroll]: snapScroll}
			);

			const bgClasses = classNames(
				styles.pageBackground,
				styles[`pageBackground${id}`],
			);

			return (
				<div className={containerClasses} ref={this.containerRef}>
					<div className={bgClasses}>
						<div className="container-fluid vh-100">
								<div className="row h-100">
									<div className="col col-12 d-none d-lg-block" ref={this.col5Ref} />
								</div>
						</div>
					</div>
					<div className={bgClasses} style={
						{
							width: `calc(100% - ${scrollbarWidth}px)`,
						}}>
						<div className="container vh-100">
							<div className="row h-100">
								<div className="col col-1 d-none d-lg-block" ref={this.col1Ref} />
								<div className="col col-12 col-md-6 col-lg-5" ref={this.col2Ref} />
								<div className="col col-md-6 col-lg-5 d-none d-md-block" ref={this.col3Ref} />
								<div className="col col-1 d-none d-lg-block" ref={this.col4Ref} />
							</div>
						</div>
					</div>
					<div className={contentClasses} ref={this.contentRef}>
						<WrappedComponent {...this.props} scrollY={this.state.scrollY} />
					</div>
				</div>
			);
		}
	}

	const mapStateToProps = (state) => {
		return {
			scrollbarWidth: state.scrollbarWidth ? state.scrollbarWidth : 0
		}
	};

	return connect(mapStateToProps, {
		setScrollbarWidth
	})(Page);
}

export default PageTransitionWrapper;
