import React from 'react';
import {connect} from 'react-redux';
import {initSitemap} from './actions';
// import styles from './App.module.scss';
import {BrowserRouter, Route} from 'react-router-dom';
import {Transition} from 'react-transition-group';
import Menu from './components/Menu/Menu';
import GridLines from './components/GridLines/GridLines';
import _find from 'lodash/find';
import * as PageID from './constants/PageID';
import innerHeight from 'ios-inner-height';
import MobileDetect from 'mobile-detect';
import _debounce from 'lodash/debounce';
import GalleryOverlay from "./components/Gallery/GalleryOverlay";

class App extends React.Component {
	constructor(props) {
		super(props);

		this.md = new MobileDetect(window.navigator.userAgent);

		this.state = {
			windowSize: {
				width: this.md.phone() ? window.outerWidth : window.innerWidth,
				height: innerHeight()
			}
		};

		// TODO this.props.setLanguage('de');
		this.defaultLanguage = 'de';

		this.state.windowSize.ratio = this.state.windowSize.width / this.state.windowSize.height;

		this.resizeListener = _debounce(this.onWindowResize.bind(this), 150);
	}

	componentDidMount() {
		this.props.initSitemap();

		this.onWindowResize();
		window.addEventListener('resize', this.resizeListener);
	}

	getIdByPath(pathName) {
		const {sitemap} = this.props;

		const result = _find(sitemap, ['path', pathName]);
		return result ? result.id : null;
	}

	getContentById(id, match, location, path) {

		const {sitemap} = this.props;

		// TODO check match for new location! old one ist null while transition!
		// if(!match) return null;
		// TODO if params.lng is undefined => use defaultLanguage
		// sitemap content by language
		// check local storage for language key
		// const selectedLanguage = typeof match.params.lng === "undefined" ? this.defaultLanguage : match.params.lng;

		const result = _find(sitemap, ['id', id]);
		return result ? result.content : null;
	}

	onWindowResize() {
		const oldWinSize = this.state.windowSize;
		const newWinSize = {
			width: this.md.phone() ? window.outerWidth : window.innerWidth,
			height: window.innerHeight //innerHeight()
		};

		newWinSize.ratio = newWinSize.width / newWinSize.height;

		if(oldWinSize.ratio !== newWinSize.ratio) {
			this.setState({windowSize: newWinSize});
		}

		// set root-container height
		if(this.props.rootEl) {
			this.props.rootEl.setAttribute('style', `height:${newWinSize.height}px`);
		}
	}

	render() {
		if(!this.props.sitemap | !this.props.rootEl) return null;

		const {sitemap, menuVisible} = this.props;
		const {windowSize} = this.state;

		return (
			<BrowserRouter>
				<Route render={({location}) => (
					<Menu routes={sitemap}
								selectedId={this.getIdByPath(location.pathname)}
								windowSize={windowSize}
					/>
				)} />

				<React.Fragment>
					{sitemap.map(({ path, id, Component, snapScroll }) => (
							<Route key={path} exact path={path}>
								{({ match, location }) => (
									<Transition
										in={match !== null}
										timeout={{
											enter: 1300,
											exit: 1300
										}}
										unmountOnExit={id !== PageID.HOME}
										mountOnEnter
									>
										{transitionState => (
											<Component transitionState={transitionState}
													   snapScroll={snapScroll}
																 id={id}
																 selectedId={this.getIdByPath(location.pathname)}
																 content={this.getContentById(id, match, location, path)}
																 menuVisible={menuVisible}
																 windowSize={windowSize}
											/>
										)}
									</Transition>
								)}
							</Route>
					))}
				</React.Fragment>

				<Route render={({location}) => (
					<GridLines selectedId={this.getIdByPath(location.pathname)}
										 menuVisible={menuVisible}/>
				)} />

				<GalleryOverlay windowSize={windowSize}/>

			</BrowserRouter>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		sitemap: state.sitemap,
		menuVisible: state.menuVisible
	}
};

export default connect(mapStateToProps, {initSitemap})(App);
