import React from 'react'
import { Link } from 'react-router-dom'
import PageTransitionWrapper from './PageTransitionWrapper'

class AboutPage extends React.Component {
	getContent(data) {
		return(
			<React.Fragment>
				{data.title &&
				 <div className="row mb-2 mb-md-3">
					 <div className="col col-1 d-none d-lg-block" />
					 <div className="col col-12 col-lg-10">
						 <h3 className="text-c-7" dangerouslySetInnerHTML={{__html: data.title}} />
					 </div>
				 </div>
				}

				{data.content.map(({ year, copy }, index) => (
					<div className="row mb-4" key={index}>
						<div className="col col-1 d-none d-lg-block" />
						<div className="col col-12 col-md-3 col-lg-2">
							<h4 className="text-c-11 text-md-right" dangerouslySetInnerHTML={{__html: year}} />
						</div>
						<div className="col col-12 col-md-9 col-lg-7">
							<p dangerouslySetInnerHTML={{__html: copy}} />
						</div>
					</div>
				))}
			</React.Fragment>
		);
	}

	render() {
		const {content} = this.props;

		if(!content) return null;

		return (
			<React.Fragment>
				<div className="container text-c-9">
					<div className="row mb-4">
						<div className="col col-12 col-md-6 col-lg-5 offset-lg-1">
							<h1 className="text-c-11" dangerouslySetInnerHTML={{__html: content.title}} />
						</div>
					</div>
				</div>

				{/*<div className="container-fluid">
					<div className="row bg-c-7 mb-4">
						<Motion defaultStyle={{scrollY: 0}}
										style={{scrollY: spring(scrollY, {stiffness: 80, damping:40})}}>
							{interpolatingStyle => {
								return (
									<Parallax windowSize={windowSize}
														data={content.parallax}
														scrollY={interpolatingStyle.scrollY}
									/>
								)
							}}
						</Motion>
					</div>
				</div>*/}

				<div className="container text-c-9">
					{this.getContent(content.overview)}

					<div className="row mb-4">
						<div className="col col-10 col-md-8 offset-1 offset-md-2">
							<hr />
						</div>
					</div>

					{this.getContent(content.education)}

					<div className="row mb-4">
						<div className="col col-10 col-md-8 offset-1 offset-md-2">
							<hr />
						</div>
					</div>

					{this.getContent(content.service)}

					<div className="row mt-5 page-bottom-padding">
						<div className="col col-12 offset-lg-1 col-lg-10 text-right">
							<Link className="button" to={content.linkDevelopment.path}
										dangerouslySetInnerHTML={{__html: content.linkDevelopment.label}} />
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default PageTransitionWrapper(AboutPage);
