import {combineReducers} from 'redux';
import sitemapReducer from './sitemapReducer';
import menuReducer from './menuReducer';
import overlayReducer from "./overlayReducer";
import scrollbarReducer from "./scrollbarReducer";

export default combineReducers(
	{
		sitemap: sitemapReducer,
		menuVisible: menuReducer,
		overlayData: overlayReducer,
		scrollbarWidth: scrollbarReducer
	}
);