import React from 'react';
import PageTransitionWrapper from './PageTransitionWrapper';
import styles from './StyleguidePage.module.scss';

class StyleGuidePage extends React.Component {

	noHref() {
		return '#';
	}

	render() {
		return (
			<div className="container">
				<div className="row">
					<div className="col col-12 col-md-6 col-lg-5 offset-lg-1">
						<h1>Hedline &lt;h1&gt; Lorem ipsum</h1>
						<h1 className="text-c-11">Hedline &lt;h1&gt; Lorem ipsum</h1>
						<h2>Hedline &lt;h2&gt;</h2>
						<h3>Hedline &lt;h3&gt;</h3>
						<h4>Hedline &lt;h4&gt;</h4>
						<h5>Hedline &lt;h5&gt;</h5>
						<p>p-default | Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce at augue elementum, hendrerit risus non, tempor ante. Vestibulum ac tristique sapien. Nulla interdum elit est, nec tempor neque fermentum ac. Donec condimentum interdum justo, ut condimentum ex vulputate ut. Integer id tincidunt erat, vitae volutpat lacus.</p>
						<a className="button" href={this.noHref()}>Button Link &raquo;</a><br />
						<button>default Button &raquo;</button>
						<p className="small">p-small | Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce at augue elementum, hendrerit risus non, tempor ante. Vestibulum ac tristique sapien. Nulla interdum elit est, nec tempor neque fermentum ac. Donec condimentum interdum justo, ut condimentum ex vulputate ut. Integer id tincidunt erat, vitae volutpat lacus.</p>
						<p className="tiny">p-tiny | Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce at augue elementum, hendrerit risus non, tempor ante. Vestibulum ac tristique sapien. Nulla interdum elit est, nec tempor neque fermentum ac. Donec condimentum interdum justo, ut condimentum ex vulputate ut. Integer id tincidunt erat, vitae volutpat lacus.</p>
						<a href={this.noHref()}>default Link</a>
					</div>

					<div className={`${styles.invertSelection} col col-12 col-md-6 col-lg-5 bg-c-7`}>
						<h1 className="text-dark">Hedline &lt;h1&gt; Lorem ipsum</h1>
						<h1 className="text-c-11">Hedline &lt;h1&gt; Lorem ipsum</h1>
						<h2 className="text-dark">Hedline &lt;h2&gt;</h2>
						<h3 className="text-dark">Hedline &lt;h3&gt;</h3>
						<h4 className="text-dark">Hedline &lt;h4&gt;</h4>
						<h5 className="text-dark">Hedline &lt;h5&gt;</h5>
						<p className="text-dark">p-default | Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce at augue elementum, hendrerit risus non, tempor ante. Vestibulum ac tristique sapien. Nulla interdum elit est, nec tempor neque fermentum ac. Donec condimentum interdum justo, ut condimentum ex vulputate ut. Integer id tincidunt erat, vitae volutpat lacus.</p>
						<a className="button text-dark" href={this.noHref()}>Button Link &raquo;</a><br />
						<button className="text-dark">default Button &raquo;</button>
						<p className="small text-dark">p-small | Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce at augue elementum, hendrerit risus non, tempor ante. Vestibulum ac tristique sapien. Nulla interdum elit est, nec tempor neque fermentum ac. Donec condimentum interdum justo, ut condimentum ex vulputate ut. Integer id tincidunt erat, vitae volutpat lacus.</p>
						<p className="tiny text-dark">p-tiny | Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce at augue elementum, hendrerit risus non, tempor ante. Vestibulum ac tristique sapien. Nulla interdum elit est, nec tempor neque fermentum ac. Donec condimentum interdum justo, ut condimentum ex vulputate ut. Integer id tincidunt erat, vitae volutpat lacus.</p>
						<a href={this.noHref()}>default Link</a>
					</div>
				</div>
			</div>
		);
	}
}

export default PageTransitionWrapper(StyleGuidePage);