import React from 'react';
import styles from './CSS3DView.module.scss';
import TimelineMax from 'gsap/TimelineMax';
import TweenMax from 'gsap/TweenMax';
import * as Ease from 'gsap/EasePack';
import SplitText from '../../lib/gsap-bonus/SplitText';
import classNames from 'classnames';
import * as MediaQuery from '../../constants/MediaQuery';
import * as MathUtil from '../../utils/MathUtil';
import _each from 'lodash/each';

class CSS3DView extends React.Component {
	constructor(props) {
		super(props);

		this.THREE = window.THREE;
		this.touchStart = new this.THREE.Vector2(0,0);
		this.group3D = new this.THREE.Group();
		this.mouse = new this.THREE.Vector2(0,0);
		this.touchStart = 0;

		this.state = {
			setupComplete: false,
			introShown: false
		};

		this.objectSettings = {
			animElements: {}, // set in this.createObjects
			elements3D: {}, // set in this.createObjects
			positions: {
				default: {
					title: new this.THREE.Vector3(-535,465,-300),
					name: new this.THREE.Vector3(-320,300,0),
					work: new this.THREE.Vector3(-530,205,-300),
					copyLeft: new this.THREE.Vector3(-460,-30,-600),
					copyRight: new this.THREE.Vector3(460,275,-600)
				},
				mobile: {
					title: new this.THREE.Vector3(135,465,-300),
					name: new this.THREE.Vector3(160,335,-150),
					work: new this.THREE.Vector3(120,230,-300),
					copyLeft: new this.THREE.Vector3(0,0,-600),
					copyRight: new this.THREE.Vector3(0,-505,-600)
				}
			}
		};

		this.cssContainerRef = React.createRef();

		this.touchMoveHandler = this.onTouchMove.bind(this);
		this.touchStartHandler = this.onTouchStart.bind(this);
	}

	componentWillUnmount() {
		document.removeEventListener('touchmove', this.touchMoveHandler);
		document.removeEventListener('touchstart', this.touchStartHandler);
	}

	componentDidMount() {
		document.addEventListener('touchmove', this.touchMoveHandler, { passive: false });
		document.addEventListener('touchstart', this.touchStartHandler);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const {windowSize, scene, renderer, content} = this.props;
		const prevWinSize = prevProps.windowSize;

		// if scene, glScene and data exists and css-setup didn't run yet => initialize css3d view
		if (!this.state.setupComplete && scene && content) {
			this.createObjects();

			this.cssContainerRef.current.appendChild(renderer.domElement);

			this.setState(
				{
					setupComplete: true
				}
			);

			if(!this.state.introShown) {
				setTimeout(() => {
					this.showIntro();
				}, 200);
			}
		}

		// WINDOW SIZE CHANGE
		if(prevWinSize && windowSize) {
			if(prevWinSize.ratio !== windowSize.ratio) {
				this.alignObjects();
			}
		}
	}

	showIntro() {
		const {name, work, title, copyLeft, copyRight} = this.objectSettings.animElements;
		const {introDuration, onStartIntro} = this.props;
		const delay = 0.8;

		onStartIntro(delay);

		// this.splTHeadline = new SplitText(detailHeadline, {type: 'lines, words, chars'});
		const splTName = new SplitText(name, {type: 'lines, words, chars'});
		const splTWork = new SplitText(work, {type: 'lines, words, chars'});
		const splTCopyLeft = new SplitText(copyLeft, {type: 'lines'});
		const splTCopyRight = new SplitText(copyRight, {type: 'lines'});
		const tl = new TimelineMax({delay});

		tl.addLabel('start');

		// #1 NAME
		tl.staggerFromTo(splTName.chars, introDuration / 6, {y: 180}, {y: 0, ease: Ease.Sine.easeOut}, 0.06, 'start+=0.1');

		// #2 WORK
		tl.staggerFromTo(splTWork.lines, introDuration / 3, {y: 180}, {y: 0, ease: Ease.Sine.easeOut}, 0.06, 'start+=1');

		// #3 TITLE
		tl.fromTo(title, introDuration / 2, {autoAlpha: 0}, {autoAlpha: 1, ease: Ease.Sine.easeOut}, 'start+=0.5');

		// #4 COPY LEFT
		tl.addLabel("copyLeft", "start+=1.3");
		// tl.fromTo(copyLeft, introDuration / 2, {autoAlpha: 0}, {autoAlpha: 1, ease: Ease.Sine.easeOut}, 'copyLeft');

		// add span to perform text align justify
		for(let i = 0; i < splTCopyLeft.lines.length - 1; i++) {
			let span = document.createElement('span');
			splTCopyLeft.lines[i].appendChild(span);
			TweenMax.set(span, {display: 'inline-block', width: '100%'});
		}

		tl.staggerFromTo(splTCopyLeft.lines, introDuration / 3, {
			y: 100,
			autoAlpha:0
		}, {
			y: 0,
			autoAlpha: 1,
			ease: Ease.Power2.easeOut,
		}, 0.03, `copyLeft`);

		// COPY RIGHT
		tl.addLabel("copyRight", "copyLeft+=0.3");
		for(let i = 0; i < splTCopyRight.lines.length - 1; i++) {
			let span = document.createElement('span');
			splTCopyRight.lines[i].appendChild(span);
			TweenMax.set(span, {display: 'inline-block', width: '100%'});
		}

		tl.staggerFromTo(splTCopyRight.lines, introDuration / 3, {
			y: 100,
			autoAlpha:0
		}, {
			y: 0,
			autoAlpha: 1,
			ease: Ease.Power2.easeOut,
		}, 0.03, `copyRight`);

		// tl.timeScale(1.2);

		if(!this.state.introShown) {
			this.setState({introShown: true});
		}
	}

	getPosition(name) {
		const propName = this.props.windowSize >= MediaQuery.SM ? 'default' : 'mobile';
		return this.positions[propName][name];
	}

	alignObjects() {
		const {elements3D, positions} = this.objectSettings;
		const {windowSize} = this.props;

		_each(elements3D, (item3D, key) => {
			const posKey = windowSize.width >= MediaQuery.SM ? 'default' : 'mobile';
			const pos = positions[posKey][key];

			item3D.position.copy(pos);
		})
	}

	createObjects() {
		const {scene, objectScale, content} = this.props;

		// title
		const $title = document.createElement('h5');
		$title.className = `text-sm-right ${styles.title} text-c-7`;
		$title.innerHTML = content.title;

		// name
		const $name = document.createElement('h1');
		$name.className = `${styles.name} text-c-11`;
		$name.innerHTML = content.name;

		// work
		const $work = document.createElement('h3');
		$work.className = `font-italic text-c-7 ${styles.work}`;
		$work.innerHTML = content.work;

		// copy left (and right on VP sm)
		const $copyLeft = document.createElement('p');
		$copyLeft.className = `text-c-6 ${styles.copyLeft}`;
		$copyLeft.innerHTML = content.copyLeft;

		// copy right
		const $copyRight = document.createElement('p');
		$copyRight.className = `${styles.copyRight} text-c-6`;
		$copyRight.innerHTML = content.copyRight;

		const title3D = new this.THREE.CSS3DObject($title);
		const name3D = new this.THREE.CSS3DObject($name);
		const work3D = new this.THREE.CSS3DObject($work);
		const copyLeft3D = new this.THREE.CSS3DObject($copyLeft);
		const copyRight3D = new this.THREE.CSS3DObject($copyRight);

		this.objectSettings.elements3D = {
			title: title3D,
			name: name3D,
			work: work3D,
			copyLeft: copyLeft3D,
			copyRight: copyRight3D
		};

		this.objectSettings.animElements = {
			title: $title,
			name: $name,
			work: $work,
			copyLeft: $copyLeft,
			copyRight: $copyRight
		};

		this.group3D.add(title3D);
		this.group3D.add(name3D);
		this.group3D.add(work3D);
		this.group3D.add(copyLeft3D);
		this.group3D.add(copyRight3D);
		this.group3D.scale.set(objectScale, objectScale, objectScale);

		this.alignObjects();

		scene.add(this.group3D);
	}

	onTouchStart(event) {
		if(event.touches.length === 1) {
			this.touchStart = new this.THREE.Vector2(event.touches[0].clientX, event.touches[0].clientY);
		}
	}

	onTouchMove(event) {
		const {pause, groupMinY, groupMaxY, touchFriction} = this.props;

		if(!pause && (event.touches.length === 1)) {
			event.preventDefault();
			//move group3D to show all content
			const clientY = event.touches[0].clientY;
			const pos = this.group3D.position.clone();
			const delta = (this.touchStart.y - clientY) * touchFriction;

			TweenMax.to(this.group3D.position, 1, {
				y: MathUtil.bounds(pos.y + delta, groupMinY, groupMaxY),
				ease: Ease.Sine.easeOut
			});
		}
	}

	render() {
		const {introShown} = this.state;

		const classes = classNames(
			styles.cssContainer,
			{[styles.introShown]: introShown}
		);

		return (
			<div
				className={classes}
				ref={this.cssContainerRef}
			/>
		)
	}
}

CSS3DView.defaultProps = {
	objectScale: 0.5,
	groupMinY: 0,
	groupMaxY: 100,
	touchFriction: 2
};

export default CSS3DView;