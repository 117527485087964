import * as ActionType from '../constants/ActionType'
import HomePage from '../pages/HomePage'
import ContactPage from '../pages/ContactPage'
import AboutPage from '../pages/AboutPage'
import DevelopmentPage from '../pages/DevelopmentPage'
import IllustrationPage from '../pages/IllustrationPage'
import StyleguidePage from '../pages/StyleguidePage'
import * as PageID from '../constants/PageID'
import { routePathWithLanguage } from '../utils/LangUtils'

// DOESN'T WORK IN EDGE / IE DEVELOPMENT-MODE
// because dev mode will not be transpiled => spread-operator throws error
// https://simpleicons.org/
import { ReactComponent as InstagramLogo } from '../static/img/instagram.svg'
import { ReactComponent as XingLogo } from '../static/img/xing.svg'
import { ReactComponent as LinkedInLogo } from '../static/img/linkedin.svg'

/* eslint-disable no-unused-vars */
// about
import image_HB_l from '../static/img/hb_l.jpg'
import image_HB_m from '../static/img/hb_m.jpg'
import image_HB_overlay from '../static/img/hb_overlay.jpg'

// gallery charcoal
import image_model_01_s from '../static/img/charcoal/model_01_s.jpg'
import image_model_01_m from '../static/img/charcoal/model_01_m.jpg'
import image_model_01_l from '../static/img/charcoal/model_01_l.jpg'

import image_model_02_s from '../static/img/charcoal/model_02_s.jpg'
import image_model_02_m from '../static/img/charcoal/model_02_m.jpg'
import image_model_02_l from '../static/img/charcoal/model_02_l.jpg'

import image_model_03_s from '../static/img/charcoal/model_03_s.jpg'
import image_model_03_m from '../static/img/charcoal/model_03_m.jpg'
import image_model_03_l from '../static/img/charcoal/model_03_l.jpg'

import image_commission_01_s from '../static/img/charcoal/commission_01_s.jpg'
import image_commission_01_m from '../static/img/charcoal/commission_01_m.jpg'
import image_commission_01_l from '../static/img/charcoal/commission_01_l.jpg'

// digital painting gallery
import image_len_s from '../static/img/digital/len_s.jpg'
import image_len_m from '../static/img/digital/len_m.jpg'
import image_len_l from '../static/img/digital/len_l.jpg'

import image_lia_s from '../static/img/digital/lia_s.jpg'
import image_lia_m from '../static/img/digital/lia_m.jpg'
import image_lia_l from '../static/img/digital/lia_l.jpg'

import image_elton_s from '../static/img/digital/elton_john_s.jpg'
import image_elton_m from '../static/img/digital/elton_john_m.jpg'
import image_elton_l from '../static/img/digital/elton_john_l.jpg'

import image_lollypop_s from '../static/img/digital/lollipop_s.jpg'
import image_lollypop_m from '../static/img/digital/lollipop_m.jpg'
import image_lollypop_l from '../static/img/digital/lollipop_l.jpg'

// gallery pencil
import image_tr_s from '../static/img/pencil/tr_s.jpg'
import image_tr_m from '../static/img/pencil/tr_m.jpg'
import image_tr_l from '../static/img/pencil/tr_l.jpg'

import image_hannes_s from '../static/img/pencil/hannes_s.jpg'
import image_hannes_m from '../static/img/pencil/hannes_m.jpg'
import image_hannes_l from '../static/img/pencil/hannes_l.jpg'

import image_model_1_s from '../static/img/pencil/model_1_s.jpg'
import image_model_1_m from '../static/img/pencil/model_1_m.jpg'
import image_model_1_l from '../static/img/pencil/model_1_l.jpg'

import image_model_2_s from '../static/img/pencil/model_2_s.jpg'
import image_model_2_m from '../static/img/pencil/model_2_m.jpg'
import image_model_2_l from '../static/img/pencil/model_2_l.jpg'

import image_dj_s from '../static/img/pencil/dwain_johnson_s.jpg'
import image_dj_m from '../static/img/pencil/dwain_johnson_m.jpg'
import image_dj_l from '../static/img/pencil/dwain_johnson_l.jpg'

/* eslint-enable no-unused-vars */

const routes = [
	{
		path: routePathWithLanguage('/'),
		id: PageID.HOME,
		name: 'Home',
		Component: HomePage,
		exact: true,
		show: true,
		content: {
			title: 'Dipl.-Medieninformatiker (FH)',
			name: 'Henk Blankenberg',
			work: 'Software Development',
			copyLeft: 'Ich arbeite seit 2004 als professioneller Software-Entwickler. Mein Fokus liegt dabei auf der Frontend-Entwicklung komplexer Web-Applikationen.',
			copyRight: 'Seit 2008 bin ich selbständig für namhafte Agenturen und Marken tätig.<br><br>Mit JavaScript entwickle ich Frontend-Systeme, die auf modernen Frameworks und Tools basieren. Es macht mir Spaß, komplexe Anforderungen und Ideen in hochwertigen Code zu transformieren.',
			linkDevelopment: {
				path: routePathWithLanguage('/development'),
				label: 'Portfolio&nbsp;&raquo;',
			},
			linkIllustration: {
				path: routePathWithLanguage('/about'),
				label: 'Über mich&nbsp;&raquo;',
			},
		},
	},
	{
		path: routePathWithLanguage('/about'),
		id: PageID.ABOUT,
		name: 'About',
		Component: AboutPage,
		show: true,
		content: {
			title: 'Über mich',
			parallax: {
				image: {
					s: image_HB_m,
					m: image_HB_m,
					l: image_HB_l,
				},
				alt: 'Henk Blankenberg',
				imageOverlay: image_HB_overlay,
			},
			linkDevelopment: {
				path: routePathWithLanguage('/development'),
				label: 'Referenzen Development&nbsp;&raquo;',
			},
			overview: {
				title: 'Überblick',
				content: [
					{
						year: '2004',
						copy: 'Nach dem Studium begann ich 2004 als Frontend-Entwickler mit dem'
							+ ' Schwerpunkt Flash-Anwendungen (ActionScript 2 und 3) in der Agentur'
							+ ' Bassier, Bergmann & Kindler (heute UDG Ludwigsburg GmbH) und'
							+ ' entwickelte Microsites für Porsche weltweit.',
					},
					{
						year: '2008',
						copy: 'Seit 2008 arbeite ich als selbständiger Software Developer deutschlandweit'
							+ ' für namhafte Agenturen und Marken. Hier entwickelte ich unter anderem'
							+ ' Fullresponsive Single Page Applications, Microsites, modern Web-Applications'
							+ ' oder auch Web-Komponenten.',
					},
					{
						year: '2011',
						copy: 'Der\n' +
							'Schwerpunkt meiner Arbeit liegt in der Entwicklung von modernen Webanwendungen mit' +
							' JavaScript/HTML5/CSS.<br>Softwarearchitektur, Frontend-Entwicklung, CSS-Design und Animation sind der Kern meiner Arbeit.' +
							' Mit JavaScript entwickle ich Frontend-Systeme, die auf modernen Frameworks und Tools basieren. Es macht mir Spaß, komplexe' +
							' Anforderungen und Ideen in hochwertigen Code zu verwandeln. Ein breites Verständnis für Webtechnologien und die zugrunde liegenden' +
							' Protokolle und Metaphern sowie eine zuverlässige und lösungsorientierte Arbeitsweise zeichnen mich aus.',
					},
				],
			},
			education: {
				title: 'Ausbildung',
				content: [
					{
						year: '1999&nbsp;-&nbsp;2004',
						copy: 'Hochschule Harz, Wernigerode<br>Studiengang Medieninformatik<br>Abschluss: Diplom Medieninformatiker (FH)',
					},
				],
			},
			service: {
				title: 'Leistungen',
				content: [
					{
						year: '',
						copy: 'Agile Softwareentwicklung, Scrum, Software-Architektur, Frontend-Development, CSS-Design und -Animation<br/><br/>'
							+ ' JavaScript, ReactJS, Vue.JS, TypeScript, Jest, HTML5, CSS / SASS / LESS, JSON, AJAX, REST, GraphQL, Playwright, Vite, Styled-Components<br/><br/>'
							+ ' Build-Systeme (webpack, npm etc.)<br/><br/>'
							+ ' Web- Animation (GSAP / TweenMax) mit Sound und Video<br/><br/>'
							+ 'Beratung und Mentoring',
					},
				],
			},

		},
	},
	{
		path: routePathWithLanguage('/development'),
		id: PageID.DEVELOPMENT,
		name: 'Software Development',
		Component: DevelopmentPage,
		show: true,
		content: {
			title: 'Eine Auswahl meiner Projekte',
			linkIllustration: {
				path: routePathWithLanguage('/illustration'),
				label: 'Portfolio Illustration&nbsp;&raquo;',
			},
			projects: [
				{
					title: 'DKB Broker (Web-Banking) - Deutsche Kreditbank AG',
					year: '2022 - 2024',
					copy: 'Im Auftrag der <a href=\'https://dkbcodefactory.com/\' target=\'_blank\'>DKB Code Factory GmbH</a> und <a href=\'https://elevatex.de/\' target=\'_blank\'>ElevateX</a><br><br>'
						+ 'Im Rahmen des Relaunches des DKB Web-Bankings arbeitete ich mit einem weiteren Entwickler am Teilbereich DKB-Broker. Wir implementierten das Web-Frontend, die es dem Nutzer unter anderem erlaubt Wertpapiere national und'
					+ 'international zu handeln sowie Sparpläne anzulegen und zu verwalten.<br>Die Anbindung der API erfolgte mittels OpenAPI. Hier nutzen wir typisierte Codegenerierung als Schnittstelle zwischen Backend und Frontend.<br>'
					+ 'Zur Sicherstellung der Codequalität nutzten wir die React Testing Library sowie Playwright für die end-to-end Tests.',
					facts: '<ul>'
						+ '<li>ReactJS, TypeScript, OpenAPI, Playwright, React Testing Library, Mock Service Worker</li>'
						+ '</ul>',
				},
				{
					title: 'THoR (Testing & Homologation Repository) - Volkswagen AG',
					year: '2021 - 2022',
					copy: 'Im Auftrag der <a href=\'https://www.ituc.de\' target=\'_blank\'>ITU consult GmbH</a> und <a href=\'https://www.link-innovation.de\' target=\'_blank\'>Link Innovation GmbH</a><br><br>'
						+ 'Ziel des Projektes war es eine markenübergreifende Software zu entwickeln zur Automatisierung der Homologation von Volkswagen. Inhalt des Projekts war die systemische Aufnahme und Verarbeitung relevanter Daten, hochverfügbare Analysen, Qualitätschecks und Reports.<br/><br/>' +
						'Meine Aufgabe war neben der Einarbeitung und dem Coaching von Frontendentwicklern, die Konzeption und Implementierung der Frontendarchitektur mit Fokus auf Skalierbarkeit hinsichtlich der Prüfprozesse für verschiedene Märkte und Sprachen. Die Anbindung der API erfolgte mittels OpenAPI. Hier nutzen wir typisierte Codegenerierung als Schnittstelle zwischen Backend und Frontend.',
					facts: '<ul>'
						+ '<li>ReactJS + TypeScript, OpenAPI + Codegeneration, SCSS, Docker</li>'
						+ '</ul>',
				},
				{
					title: 'AOK Refresh und Pflegebackend',
					year: '2020 - 2021',
					copy: 'Im Auftrag der <a href=\'https://www.queo.de/\' target=\'_blank\'>queo GmbH, Dresden</a><br><br>'
						+ 'Im Zuge des Redesigns der AOK entwickelte ich als Teil eines Teams UI Komponenten als auch eine Filial-, Krankenhaus- und Arztsuche unter Nutzung von Gatsby, ReactJS und TailwindCSS. ' +
						'Das dazugehörige Pflegebackend wurde mit ReactJS und Typescript umgesetzt. Die Anbindung der API erfolgte mittels GraphQL.',
					url: '<a href=\'https://www.aok.de\' target=\'_blank\'>www.aok.de</a><br><a href=\'https://www.aok.de/pk/plus/kontakt/aok-vor-ort\' target=\'_blank\'>www.aok.de/pk/plus/kontakt/aok-vor-ort</a>',
					facts: '<ul>'
						+ '<li>Fullresponsive UI Komponenten auf Basis von ReactJS, TailwindCSS</li>'
						+ '<li>Pflegebackend: GraphQL, ReactJS + TypeScript</li>'
						+ '</ul>',
				},
				{
					title: 'Small Luxury Hotels of the World',
					year: '2019 - 2020',
					copy: 'Im Auftrag der <a href=\'https://namics.com\' target=\'_blank\'>Namics GmbH - A Merkle Company</a><br><br>'
						+ 'Als Teil eines internationalen Teams bestand meine Aufgabe in der Entwicklung von Frontend-Komponenten.'
						+ 'Die Seiten wurden auf Basis von Sitecore unter Nutzung von NitroJS, ReactJS + TypeScript umgesetzt.',
					url: '<a href=\'https://www.slh.com\' target=\'_blank\'>www.slh.com</a>',
					facts: '<ul>'
						+ '<li>Fullresponsive Website auf Basis von Sitecore</li>'
						+ '<li>SASS, NitroJS, Handlebars Templating, ReactJS + TypeScript</li>'
						+ '</ul>',
				},
				{
					title: 'Blum Inspirations',
					year: '2019',
					copy: 'Im Auftrag der <a href=\'https://www.21torr.com\' target=\'_blank\'>21Torr GmbH, Stuttgart</a><br><br>'
						+ 'Umsetzung einer interaktiven 3D Microsite für die <a href=\'https://www.blum.com/\' target=\'_blank\'>Blum GmbH</a> zur '
						+ 'Darstellung von News- und Produkt-Teasern, incl. Video. Meine Aufgabe '
						+ 'war die Architektur und Umsetzung der webbasierten 3D-Anwendung. '
						+ 'Sowie Beratung im Bereich Web3D.',
					facts: '<ul>'
						+ '<li>Fullresponsive Single Page App auf Basis von ReactJS und ThreeJS</li>'
						+ '<li>3D Darstellung mit WebGL, CSS3D, Partikelanimation</li>'
						+ '<li>SASS</li>'
						+ '<li>Animationen mittels TweenMax und ThreeJS</li>'
						+ '<li>Navigation abhängig vom Device, Optimierung für Desktop-Touch, '
						+ 'Desktop-Mouse, Mobile Touch und Deviceorientation</li>'
						+ '<li>Build-Setup mit webpack und node</li>'
						+ '</ul>',
				},
				{
					title: 'Coke Vereinsgeschichte',
					year: '2019',
					copy: 'Im Auftrag der <a href=\'https://www.s-v.de\' target=\'_blank\'>Scholz und Volkmer GmbH</a><br><br>'
						+ 'Als Teil eines kleinen Teams entwickelten wir eine Gewinnspiel-Microsite zum spenden von Deckelcodes an DFB-Vereine.' +
						' Teil der Microsite war eine Vereinssuche incl. Google Map - Visualisierung mit Clustering, Registrierung sowie Live-Ranking.',
					url: '<a href=\'https://www.youtube.com/watch?v=svHeFoiQaiM&ab_channel=Coca-Cola\' target=\'_blank\'>Coke Vereinsgeschichte</a>',
					facts: '<ul>'
						+ '<li>Single Page App auf Basis von Vue.JS\n</li>'
						+ '<li>SASS</li>'
						+ '<li>Google Map API incl. Marker-Clustering</li>'
						+ '</ul>',
				},
				{
					title: 'Hammer Heimtex und Schlau-Großhandel',
					year: '2017&nbsp;-&nbsp;2019',
					copy: 'Im Auftrag der <a href=\'https://namics.com\' target=\'_blank\'>Namics GmbH - A Merkle Company</a><br><br>'
						+ 'Als Teil eines agilen Teams bestand meine Aufgabe in der Frontend '
						+ 'Umsetzung des Hammer-Heimtex Webauftrittes und Shops als auch des Schlau-Großhandels. '
						+ 'Die Seiten wurden auf Basis von SAP Hybris unter Nutzung von TerrificJS umgesetzt.<br><br>Weitere Aufgaben waren '
						+ 'Beratung, Mentoring und Einarbeitung neuer Frontend-Entwickler.',
					url: '<a href=\'https://www.hammer-heimtex.de\' target=\'_blank\'>www.hammer-heimtex.de</a><br>'
						+ '<a href=\'https://www.schlau-grosshandel.de/\' target=\'_blank\'>www.schlau-grosshandel.de</a>',
					facts: '<ul>'
						+ '<li>Fullresponsive Website auf Basis von SAP Hybris und Bootstrap</li>'
						+ '<li>SASS, TerrificJS</li>'
						+ '<li>JSP Templating</li>'
						+ '<li>Build-Setup mit webpack und node</li>'
						+ '</ul>',
				},
				{
					title: 'Kaufland',
					year: '2016&nbsp;-&nbsp;2017',
					copy: 'Im Auftrag der <a href=\'https://namics.com\' target=\'_blank\'>Namics GmbH - A Merkle Company</a><br><br>'
						+ 'Als Teil eines agilen Teams habe ich an der Frontend Umsetzung der '
						+ 'Kaufland Website mitgearbeitet und entwickelte Komponenten und Seiten '
						+ 'für Kaufland.de.',
					url: '<a href=\'https://www.kaufland.de\' target=\'_blank\'>www.kaufland.de</a>',
					facts: '<ul>'
						+ '<li>JavaScript, TerrificJS</li>'
						+ '<li>Handlebars- und JSP-Templating</li>'
						+ '</ul>',
				},
				{
					title: 'Werner von Siemens',
					year: '2016',
					copy: 'Im Auftrag der <a href=\'https://namics.com\' target=\'_blank\'>Virtual Identity AG</a><br><br>'
						+ 'Eine Interaktive Story zum 200. Geburtstag von Werner von Siemens in '
						+ 'vier Episoden. Im Team von zwei Entwicklern haben wir die '
						+ 'Jubiläumsseite umgesetzt - sowohl Seiten-Architektur als auch Animation '
						+ 'und Sound-Integration.',
					facts: '<ul>'
						+ '<li>Fullresponsive Single Page App auf Basis von BackboneJS</li>'
						+ '<li>SASS, eigenes Grid-System</li>'
						+ '<li>Handlebars Templating</li>'
						+ '<li>Scrollbasierte Animationen mittels ScrollMagic und TweenMax</li>'
						+ '<li>Animation, Sound und Video</li>'
						+ '</ul>',
				},
				{
					title: 'DPDHL Geschäftsbericht 2015',
					year: '2016',
					copy: 'Im Auftrag der <a href=\'https://www.21torr.com/\' target=\'_blank\'>21Torr GmbH, Stuttgart</a><br><br>'
						+ 'Auf Basis von ReactJS haben wir (2 Entwickler) eine Single Page '
						+ 'Applikation entwickelt, bestehend aus einem animiertem Imageteil und '
						+ 'dem Finanzbericht, inclusive In-Page-Suche und Druckversion.',
					facts: '<ul>'
						+ '<li>Fullresponsive Single Page App auf Basis von ReactJS</li>'
						+ '<li>SASS, eigenes Grid-System</li>'
						+ '<li>HTML5-History (PushState) Routing</li>'
						+ '<li>Flux (Reflux)</li>'
						+ '<li>Internes Caching, so dass wiederholte Seitenaufrufe beschleunigt\n'
						+ 'angezeigt werden.</li>'
						+ '<li>Laden von statischem Markup zur Darstellung des Finanzteils</li>'
						+ '</ul>',
				},
				{
					title: 'Dynamo Dresden<br>mobile Website',
					year: '2015&nbsp;-&nbsp;2016',
					copy: 'Im Auftrag der <a href=\'https://www.queo.de/\' target=\'_blank\'>queo GmbH, Dresden</a><br><br>'
						+ 'Als Ergänzung zur Hauptseite (<a href=\'http://www.dynamo-dresden.de\' target=\'_blank\'>www.dynamo-dresden.de</a>) haben wir '
						+ 'auf Basis von ReactJS eine mobile Single Page Applikation entwickelt, '
						+ 'welche über eine REST-API mit dem bestehenden TYPO3 verbunden ist.',
					url: '<a href=\'https://mobil.dynamo-dresden.de/\' target=\'_blank\'>mobil.dynamo-dresden.de</a><br>',
					facts: '<ul>'
						+ '<li>Single Page App auf Basis von ReactJS</li>'
						+ '<li>SASS, eigenes Grid-System</li>'
						+ '<li>HTML5-History (PushState)</li>'
						+ '<li>RoutingFlux (Reflux)</li>'
						+ '<li>Internes Caching, so dass wiederholte Seitenaufrufe beschleunigt\n'
						+ 'angezeigt werden.</li>'
						+ '<li>REST-Anbindung an TYPO3</li>'
						+ '</ul>',
				},
				{
					title: 'Veranstaltungskalender Dresden',
					year: '2014',
					copy: 'Im Auftrag der <a href=\'https://www.queo.de/\' target=\'_blank\'>queo GmbH, Dresden</a><br><br>'
						+ 'Suchportal für alle Veranstaltungen in und um Dresden. Fullresponsive Single Page App auf Basis von BackboneJS.',
					url: '<a href=\'https://veranstaltungen.dresden.de/\' target=\'_blank\'>veranstaltungen.dresden.de</a><br>',
					facts: '<ul>'
						+ '<li>Single Page App auf Basis von BackboneJS</li>'
						+ '<li>LESS, zzgl. einzelner Bootstrap Komponenten</li>'
						+ '<li>Handlebars Templating</li>'
						+ '<li>HTML5-History (PushState) Routing</li>'
						+ '<li>Internes Caching, so dass wiederholte Seitenaufrufe beschleunigt angezeigt werden.</li>'
						+ '<li>REST-Anbindung an Symfony2 Backend</li>'
						+ '</ul>',
				},
			],
		},
	},
	{
		path: routePathWithLanguage('/illustration'),
		id: PageID.ILLUSTRATION,
		name: 'Illustration',
		Component: IllustrationPage,
		show: true,
		snapScroll: true,
		content: {
			title: 'Portfolio Illustration',
			tmp: 'mehr gibt es auf <a href=\'https://www.instagram.com/henkblankenberg/\' target=\'_blank\'>Instagram</a> zu sehen',
			linkContact: {
				path: routePathWithLanguage('/contact'),
				label: 'Kontakt aufnehmen&nbsp;&raquo;',
			},
			galleries: [
				{
					title: 'Charcoal drawings',
					linkTitle: 'more',
					slides: [
						{
							alt: 'charcoal image by Henk Blankenberg',
							image: {
								s: image_model_01_s,
								m: image_model_01_m,
								l: image_model_01_l,
							},
							imageOverlay: image_model_01_m,
						},
						{
							alt: 'charcoal image by Henk Blankenberg',
							image: {
								s: image_model_02_s,
								m: image_model_02_m,
								l: image_model_02_l,
							},
							imageOverlay: image_model_02_m,
						},
						{
							alt: 'charcoal image by Henk Blankenberg',
							image: {
								s: image_model_03_s,
								m: image_model_03_m,
								l: image_model_03_l,
							},
							imageOverlay: image_model_03_m,
						},
						{
							alt: 'charcoal image by Henk Blankenberg',
							image: {
								s: image_commission_01_s,
								m: image_commission_01_m,
								l: image_commission_01_l,
							},
							imageOverlay: image_commission_01_m,
						},
					],
				},
				{
					title: 'Digital painting',
					linkTitle: 'more',
					slides: [
						{
							alt: 'digital painting by Henk Blankenberg',
							image: {
								s: image_len_s,
								m: image_len_m,
								l: image_len_l,
							},
							imageOverlay: image_len_m,
						},
						{
							alt: 'digital painting by Henk Blankenberg',
							image: {
								s: image_lia_s,
								m: image_lia_m,
								l: image_lia_l,
							},
							imageOverlay: image_lia_m,
						},
						{
							alt: 'digital painting by Henk Blankenberg',
							image: {
								s: image_elton_s,
								m: image_elton_m,
								l: image_elton_l,
							},
							imageOverlay: image_elton_m,
						},
						{
							alt: 'digital painting by Henk Blankenberg',
							description: 'inspired by Chris Guest <a href=\'https://www.chrisguest.co.uk/copy-of-2017?lightbox=dataItem-jpqzodo8\' target=\'_blank\' rel=\'noopener noreferrer\'>www.chrisguest.co.uk</a>',
							image: {
								s: image_lollypop_s,
								m: image_lollypop_m,
								l: image_lollypop_l,
							},
							imageOverlay: image_lollypop_m,
						},
					],
				},
				{
					title: 'Pencil drawings',
					linkTitle: 'more',
					slides: [
						{
							alt: 'pencil drawing by Henk Blankenberg',
							image: {
								s: image_model_1_s,
								m: image_model_1_m,
								l: image_model_1_l,
							},
							imageOverlay: image_model_1_m,
						}, {
							alt: 'pencil drawing by Henk Blankenberg',
							image: {
								s: image_tr_s,
								m: image_tr_m,
								l: image_tr_l,
							},
							imageOverlay: image_tr_m,
						},
						{
							alt: 'pencil drawing by Henk Blankenberg',
							image: {
								s: image_hannes_s,
								m: image_hannes_m,
								l: image_hannes_l,
							},
							imageOverlay: image_hannes_m,
						},
						{
							alt: 'pencil drawing by Henk Blankenberg',
							image: {
								s: image_model_2_s,
								m: image_model_2_m,
								l: image_model_2_l,
							},
							imageOverlay: image_model_2_m,
						},
						{
							alt: 'pencil drawing by Henk Blankenberg',
							image: {
								s: image_dj_s,
								m: image_dj_m,
								l: image_dj_l,
							},
							imageOverlay: image_dj_m,
						},

					],
				},
			],
		},
	},
	{
		path: routePathWithLanguage('/contact'),
		id: PageID.CONTACT,
		name: 'Kontakt / Impressum',
		Component: ContactPage,
		show: true,
		content: {
			title: 'Kontakt und<br>Impressum',
			subHeadline: 'Angaben gem. § 5 TMG',
			chapters: [
				{
					title: 'Kontakt',
					copy: 'Henk Blankenberg<br>Käthe-Kollwitz-Ufer 92<br>01309 Dresden<br><br>'
						+ 'Mobil: <a href=\'tel:+4915142328614\'>+49 (0)151 42328614</a><br>'
						+ 'EMail: <a href=\'mailto:info@henk-blankenberg.de\'>info@henk-blankenberg.de</a>',
				},
				{
					title: 'Umsatzsteuer-Identifikationsnummer<br>gem. § 27a UStG',
					copy: 'DE258039902<br>',
				},
				{
					title: 'Inhaltlich Verantwortlicher<br>gem. § 55 II RStV',
					copy: 'Henk Blankenberg<br>Käthe-Kollwitz-Ufer 92<br>01309 Dresden<br><br>',
				},
			],
		},
	},
	{
		path: routePathWithLanguage('/styleguide'),
		id: PageID.STYLE_GUIDE,
		name: 'Styleguide',
		Component: StyleguidePage,
		show: false,
	},
]

const iconLinks = [
	{
		id: 'linkedIn',
		path: 'https://www.linkedin.com/in/henk-blankenberg-0a1587113/',
		Icon: LinkedInLogo,
		show: true,
		iconLink: true,
	},
	{
		id: 'xing',
		path: 'https://www.xing.com/profile/Henk_Blankenberg',
		Icon: XingLogo,
		show: true,
		iconLink: true,
	},
	{
		id: 'instagram',
		name: 'Instagram',
		path: 'https://www.instagram.com/henkblankenberg/',
		Icon: InstagramLogo,
		show: true,
		iconLink: true,
	},
]

const sitemapReducer = (state = null, action) => {
	switch (action.type) {
		case ActionType.INIT_SITEMAP:
			return [...routes, ...iconLinks]

		default:
			return state
	}
}

export default sitemapReducer
