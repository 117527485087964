import React from 'react';
import styles from './GridLines.module.scss';
import classNames from 'classnames';
import {connect} from 'react-redux';

class GridLines extends React.Component {
	render() {
		const {selectedId, menuVisible, scrollbarWidth} = this.props;

		const containerClasses = classNames(
			styles.container,
			{[styles.menuVisible] : menuVisible}
		);
		const baseLine = classNames(
			styles.line,
			styles[`line${selectedId}`]
		);

		return (
			<div className={containerClasses}>
				<div className="container h-100" style={
					{
						width: `calc(100% - ${scrollbarWidth}px)`,
						position: 'relative',
						left: `-${Math.floor(scrollbarWidth / 2)}px`
					}}>
					<div className="row h-100">
						<div className="col col-1 d-none d-lg-block">
							<span className={`${baseLine} ${styles.visibleXL}`}/>
							<span className={`${baseLine} ${styles.lineRight}`}/>
						</div>
						<div className="col col-12 col-md-6 col-lg-5">
							{/*<span className={`${baseLine} ${styles.lineRight} ${selectedId === PageID.HOME ? '' : 'd-none'}`}/>*/}
							<span className={`${baseLine} ${styles.lineRight}`}/>
						</div>
						<div className="col col-md-6 col-lg-5 d-none d-md-block" />
						<div className="col col-1 d-none d-lg-block">
							<span className={`${baseLine}`}/>
							<span className={`${baseLine} ${styles.lineRight} ${styles.visibleXL}`}/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		scrollbarWidth: state.scrollbarWidth ? state.scrollbarWidth : 0
	}
};

export default connect(mapStateToProps)(GridLines);