import React from 'react';
import * as Ease from 'gsap/EasePack';
import TweenMax from 'gsap/TweenMax';
import * as MathUtil from '../../utils/MathUtil';

require('../../lib/gsap-bonus/ThrowPropsPlugin');
require('../../lib/polyfill/WheelListener');

class CameraControl extends React.Component {
	constructor(props) {
		super(props);

		this.THREE = window.THREE;
		this.addWheelListener = window.addWheelListener;

		this.state = {
			setupComplete: false,
			initialOrientationChange: false,
			iOSInfoShown:false
		};

		this.touchStart = new this.THREE.Vector2(0,0);
		this.mouse = new this.THREE.Vector2(0, 0);
		this.touchStartDistance = 0;

		this.mouseScrollHandler = this.onDocumentMouseScroll.bind(this);
		this.touchMoveHandler = this.onTouchMove.bind(this);
		this.touchStartHandler = this.onTouchStart.bind(this);
	}

	componentWillUnmount() {
		document.removeEventListener('touchmove', this.touchMoveHandler);
		document.removeEventListener('touchstart', this.touchStartHandler);
	}

	componentDidMount() {
		this.addWheelListener(document, this.mouseScrollHandler);
		document.addEventListener('touchmove', this.touchMoveHandler, { passive: false });
		document.addEventListener('touchstart', this.touchStartHandler);
	}

	updateCameraPos(delta = 0, duration = 2, useTween = true) {
		const {camera, cameraSetup} = this.props;

		const pos = camera.position.clone();
		const nextPos =  MathUtil.bounds(
			pos.z + delta,
			cameraSetup.minZ,
			cameraSetup.startZ
		);

		if(useTween) {
			TweenMax.to(camera.position, duration, {
				z: nextPos,
				ease: Ease.Sine.easeOut,
			});
		}else {
			camera.position.z = nextPos;
		}
	}

	onTouchStart(event) {
		if(event.touches.length === 2) {
			const p1 = {x: event.touches[0].clientX, y: event.touches[0].clientY};
			const p2 = {x: event.touches[1].clientX, y: event.touches[1].clientY};

			this.touchStartDistance = Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
		}
	}

	onTouchMove(event) {
		const {pause} = this.props;

		if (!pause && (event.touches.length === 2)) {
			event.preventDefault();
			const p1 = {x: event.touches[0].clientX, y: event.touches[0].clientY};
			const p2 = {x: event.touches[1].clientX, y: event.touches[1].clientY};

			const distance = Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
			const delta = this.touchStartDistance - distance;

			this.updateCameraPos(delta, 0.5);
		}
	}

	onDocumentMouseScroll(event) {
		if(!this.props.pause) {
			// if deltaMode === 1 => mozilla scrollwheel
			const delta = event.deltaMode === 1 ? event.deltaY * 20 : event.deltaY;
			this.updateCameraPos(delta * this.props.scrollFriction);
		}
	}

	render() {
		return null;
	}
}

CameraControl.defaultProps = {
	scrollFriction: 3,
	touchClickDelay: 200
};

export default CameraControl;