export const SCALE_PROPORTIONAL_INSIDE = "SCALE_PROPORTIONAL_INSIDE";
export const SCALE_PROPORTIONAL_OUTSIDE = "SCALE_PROPORTIONAL_OUTSIDE";

export const scale = (width, height, targetWidth, targetHeight, scaleMode) => {
	let newScale = targetWidth / width;
	let newW = width * newScale;
	let newH = height * newScale;

	if (scaleMode === SCALE_PROPORTIONAL_INSIDE) {
		if (newH > targetHeight) {
			newScale = targetHeight / height;
			newW = width * newScale;
			newH = height * newScale;
		}
	}

	if (scaleMode === SCALE_PROPORTIONAL_OUTSIDE) {
		if (newH < targetHeight) {
			newScale = targetHeight / height;
			newW = width * newScale;
			newH = height * newScale;
		}
	}

	const margin = {left: Math.floor((targetWidth - newW) * 0.5), top: Math.floor((targetHeight - newH) * 0.5)};

	return {
		size: {
			width: Math.ceil(newW),
			height: Math.ceil(newH)
		},
		margin: margin
	};
};