export default class MovingParticleMaterial {
	constructor(options, texture) {

		this.THREE = window.THREE;

		const loader = new this.THREE.TextureLoader().load(
			texture
		);

		var shaderMaterial = new this.THREE.ShaderMaterial(
			{
				uniforms: {
					texture: { value: loader },
					time: { value: 0.1 },
					period: { value: 10.0 },
					amplitude: { value: 50 }
				},
				vertexShader: `
				#define PI 3.14159265359
				
				float map (float value, float in_min, float in_max, float out_min, float out_max) {
					float result = (value - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
					result = result > out_max ? out_max : result;
					return result < out_min ? out_min : result;
				}

				attribute float size;
				attribute float uid;
				
				uniform float time;
				uniform float period;
				uniform float amplitude;
				
				varying vec3 vColor;
				varying float vAlpha;
				
				void main() {
					vColor = color;
					
					vec4 modPosition = vec4( position, 1.0 );
					vec4 wavePosition = modPosition;
					wavePosition.x = modPosition.x + amplitude * cos( ( time + uid ) * 2.0 * PI / period );
					wavePosition.y = modPosition.y + amplitude * sin( ( time + uid ) * 3.0 * PI / period );
					wavePosition.z = modPosition.z + amplitude * cos( ( time + uid ) * 1.0 * PI / period );
					
					vec4 mvPosition = modelViewMatrix * wavePosition;
					
					float zSize = size * ( 4000.0 / -mvPosition.z * .5 );
					zSize = zSize > size * 0.5 ? size * 0.5 : zSize;
					float modSize = zSize * ( 1.0 + sin( 0.1 * uid + ( time * 5.0 ) ) );
					
					vAlpha = map(mvPosition.z, -1500.0, 0.0, .15, 1.0);
					
					gl_PointSize = modSize; 
					gl_Position = projectionMatrix * mvPosition;
				}
				`,
				fragmentShader: `
				uniform sampler2D texture;
				varying vec3 vColor;
				varying float vAlpha;
				
				void main() {
					gl_FragColor = vec4( vColor, vAlpha );
					gl_FragColor = gl_FragColor * texture2D( texture, gl_PointCoord );
					if ( gl_FragColor.a < .1 ) discard;
				}
				`,
				...options
			});

		return new this.THREE.ShaderMaterial(shaderMaterial)
	}
}