import React from 'react';
import {Link} from 'react-router-dom';
import styles from './Menu.module.scss';
import TimelineMax from 'gsap/TimelineMax';
import TweenMax from 'gsap/TweenMax';
import * as Ease from "gsap/EasePack";
import classNames from 'classnames';
import {setMenuState} from "../../actions";
import {connect} from 'react-redux';

function MenuToggle(props) {
	return (
		<div className={styles.menuToggle} onClick={props.onClick}>
			<span className={`${styles.labelMenu} ${props.menuVisible ? styles.labelMenuOpen : ''}`}>MENU</span>
			<span className={`${styles.labelClose} ${props.menuVisible ? styles.labelCloseOpen : ''}`}>CLOSE</span>
		</div>
	);
}

class Menu extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			setupComplete: false
		};

		this.tl = null;

		this.bgLightRef = React.createRef();
		this.bgLightRef = React.createRef();
		this.bgDarkRef = React.createRef();
		this.itemContainerRef = React.createRef();
		this.menuContainerRef = React.createRef();

		this.menuToggleHandler = this.onToggleMenu.bind(this);
		this.documentListener = this.onDocumentClick.bind(this);

		document.addEventListener('click', this.documentListener);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.documentListener);
	}

	componentDidMount() {
		this.initTimeline();

		this.tl.gotoAndStop('start');

		this.setState(
			{setupComplete: true}
		);
	}

	componentDidUpdate(prevProps, prevState) {
		const {menuVisible, windowSize} = this.props;
		const prevWinSize = prevProps.windowSize;

		// MENU STATE CHANGE
		if(prevState.menuVisible !== menuVisible) {
			if(menuVisible) {
				this.show();
			}else {
				this.hide()
			}
		}

		// WINDOW SIZE CHANGE
		if(prevWinSize && windowSize) {
			if(prevWinSize.ratio !== windowSize.ratio) {
				this.onWindowResize();
			}
		}
	}

	onDocumentClick(event) {
		const target = event.target;
		const {menuVisible} = this.props;
		const menuContainer = this.menuContainerRef.current;

		if(!menuContainer.contains(target) && menuVisible) {
			this.onToggleMenu();
		}
	}

	show(instantly = false) {
		if(instantly) {
			this.tl.gotoAndStop('end');
		}else {
			this.tl.timeScale(1);
			this.tl.play('start');
		}
	}

	hide() {
		this.tl.timeScale(1.5);
		this.tl.reverse();
	}

	initTimeline() {
		// kill tl if exists
		if(this.tl) {
			this.tl.kill();
			this.tl = null;
		}

		// init transition
		const duration = 0.7;

		this.tl = new TimelineMax();

		this.tl.addLabel('start');
		this.tl.fromTo([this.bgLightRef.current, this.itemContainerRef.current], duration,
									 {y: '100vh', height: 0},
									 {y: 0, height: '100vh', ease: Ease.Power2.easeOut},
									 'start+=0.1');

		this.tl.fromTo(this.bgDarkRef.current, duration,
									 {y: '100vh', height: 0},
									 {y: 0, height: '100vh', ease: Ease.Sine.easeInOut},
									 'start');

		TweenMax.set(this.itemContainerRef.current.children, {transformPerspective:600, perspective:300, transformStyle:"preserve-3d"});
		this.tl.staggerFromTo(this.itemContainerRef.current.children, duration / 2,
													{y: 10, autoAlpha: 0, rotationX: -80},
													{y: 0, autoAlpha: 1, rotationX: 0, ease: Ease.Sine.easeOut, force3D: true, transformOrigin: 'bottom left'},
													0.05, 'start+=0.1');
		this.tl.addLabel('end');
	}

	onWindowResize() {
		this.initTimeline();

		if(this.props.menuVisible) {
			this.show(true);
		}else {
			this.tl.gotoAndStop('start');
		}
	}

	onToggleMenu() {
		const {menuVisible} = this.props;

		this.props.setMenuState(!menuVisible);
	}

	getLinkClasses(id) {
		const {selectedId} = this.props;

		const linkClasses = classNames(
			styles.menuLink,
			{[styles.menuLinkActive]: id === selectedId}
		);

		return linkClasses;
	}

	getLink(data, selectedId) {
		if(!data.show) return;

		// external links
		if(data.iconLink) {
			const {Icon} = data;

			return (
				<a href={data.path}
					 target="_blank"
					 className={styles.externalLink}
					 rel="noopener noreferrer" // necessary for links with target=_blank
				>
					<Icon />
				</a>
			);
		}else {
			// internal links
			return (
				<Link to={data.path}
							className={this.getLinkClasses(data.id)}
							onClick={data.id === selectedId ? null : this.menuToggleHandler}
				>
					{data.name}
				</Link>
			);
		}

	}

	render() {
		const {routes, selectedId, menuVisible, scrollbarWidth} = this.props;
		const {setupComplete} = this.state;

		return (
			<div className={`${styles.container} ${setupComplete ? '' : 'invisible'} ${selectedId}`} style={
				{
					width: `calc(100% - ${scrollbarWidth}px)`
				}}>
				<div className="container">
					<div className="row" ref={this.menuContainerRef}>
						<div className={`${styles.menuContainer} col col-12 col-md-6 col-lg-5 offset-md-6`} >
							<div className="container px-0">
								<div className="row">
									<div className="col col-12">
										<span className={styles.bgLayerLight} ref={this.bgLightRef}/>
										<span className={styles.bgLayerDark} ref={this.bgDarkRef}/>
										<div className="row">
											<div className="col col-10 offset-1">
												<span className={styles.itemContainer} ref={this.itemContainerRef}>
													{routes.map((linkData, index) => (
														<React.Fragment key={index}>
															{this.getLink(linkData, selectedId)}
														</React.Fragment>
													))}
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="d-lg-none">
								<MenuToggle onClick={this.menuToggleHandler} menuVisible={menuVisible}/>
							</div>
						</div>

						<div className="col col-1 d-none d-lg-block text-center">
							<MenuToggle onClick={this.menuToggleHandler} menuVisible={menuVisible}/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		menuVisible: state.menuVisible,
		scrollbarWidth: state.scrollbarWidth ? state.scrollbarWidth : 0
	}
};

export default connect(mapStateToProps, {
	setMenuState
})(Menu);