import * as ActionType from "../constants/ActionType";

const menuReducer = (state = null, action) => {
	switch (action.type) {
		case ActionType.SET_MENU_STATE:
			return action.payload;

		default:
			return state;
	}
};

export default menuReducer;