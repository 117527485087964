import * as ActionType from "../constants/ActionType";

const scrollbarReducer = (state = null, action) => {
	switch (action.type) {
		case ActionType.SET_SCROLLBAR_WIDTH:
			return action.payload;

		default:
			return state;
	}
};

export default scrollbarReducer;