import React from 'react';
import PageTransitionWrapper from './PageTransitionWrapper';
import {Link} from "react-router-dom";

class DevelopmentPage extends React.Component {
	render() {
		const {content} = this.props;

		if(!content) return null;

		return (
			<div className="container">
				<div className="row mb-4">
					<div className="col col-12 col-md-8 col-xl-6 offset-lg-1">
						<h1 className="text-c-11" dangerouslySetInnerHTML={{__html: content.title}} />
					</div>
				</div>

				{content.projects.map((data, index) => (
					<React.Fragment key={index}>
						<div className="row mb-2 mb-md-3">
							<div className="col col-12 col-lg-10 offset-lg-1">
								<h3 className="text-c-7" dangerouslySetInnerHTML={{__html: data.title}} />
							</div>
						</div>

						<div className="row">
							<div className="col col-12 col-md-3 col-lg-2 offset-lg-1">
								<h4 className="text-c-11 text-md-right" dangerouslySetInnerHTML={{__html: data.year}} />
							</div>
							<div className="col col-12 col-md-9 col-lg-7">
								<p dangerouslySetInnerHTML={{__html: data.copy}} />
							</div>
						</div>

						<div className="row">
							<div className="col col-12 col-md-3 col-lg-2 offset-lg-1" />
							<div className="col col-12 col-md-9 col-lg-7">
								<p dangerouslySetInnerHTML={{__html: data.url}} />
							</div>
						</div>

						<div className="row mb-4">
							<div className="col col-12 col-md-3 col-lg-2 offset-lg-1" />
							<div className="col col-12 col-md-9 col-lg-7">
								<p dangerouslySetInnerHTML={{__html: data.facts}} />
							</div>
						</div>

						{index < content.projects.length - 1 &&
							<div className="row mb-4">
								<div className="col col-8 offset-2">
									<hr/>
								</div>
							</div>
						}

						{index === content.projects.length - 1 &&
							<div className="row mt-5 page-bottom-padding">
							 <div className="col col-12 offset-lg-1 col-lg-10 text-right">
								 <Link className="button" to={content.linkIllustration.path}
											 dangerouslySetInnerHTML={{__html: content.linkIllustration.label}} />
							 </div>
							</div>
						}
					</React.Fragment>
				))}
			</div>
		);
	}
}

export default PageTransitionWrapper(DevelopmentPage);