import * as ActionType from "../constants/ActionType";

const overlayReducer = (state = null, action) => {
	switch (action.type) {
		case ActionType.TOGGLE_GALLERY_OVERLAY:
			return action.payload;

		default:
			return state;
	}
};

export default overlayReducer;